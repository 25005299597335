html,
body {
  height: 100%;
  width: 100%;
  /* overflow: hidden; */
}

.App {
  text-align: center;
  height: 100%;
  width: 100%;
  overflow-x: hidden;
}

.wrap {
  height: 100vh;
  display: flex;
}

.side {
  border: 1px solid black;
  /* position: fixed; */
  min-width: 240px;
  background-color: #f57e16;
}

.wall {
  width: 100vw;
  /* position: fixed; */
  display: flex;
  justify-content: center;
  align-items: center;
  background-image: linear-gradient(to right, #8e0e00, #470101);
}
